import { BlogCard } from '@components/Blog/BlogCard';
import { BlogTileData } from '@interfaces/Blog';

import { featuredBlogs } from '../../data/featuredBlogs';

import { BulkCarousel } from './Carousel';

export function FeaturedBlogsCarousel() {
  return (
    <BulkCarousel>
      {featuredBlogs.map((blog, idx) => (
        <BlogCard
          blog={blog as unknown as BlogTileData}
          key={`featuredBlog${idx}`}
        />
      ))}
    </BulkCarousel>
  );
}
