export function removeUndefined(obj: Record<string, any>): Record<string, any> {
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      // Array
      (obj[key] as any[]).forEach((element, idx, arr) => {
        if (element !== null) {
          removeUndefined(element);
        }
      });
    } else if (
      Object.prototype.toString.call(obj[key]) === '[object Object]' &&
      obj[key] !== null
    ) {
      // Object
      obj[key] = removeUndefined(obj[key]);
    } else {
      // value
      // eslint-disable-next-line no-unused-expressions
      obj[key] === undefined && delete obj[key];
    }
  });

  return obj;
}
